/* stylelint-disable scss/dollar-variable-colon-space-after */
// Variables

// Color system
// TODO: Add color definitions
$colors: (
  "n100": #1a1618,
  "n90": #5d5458,
  "n80": #6d6568,
  "n70": #7e7679,
  "n60": #9c9296,
  "n50": #aba4a9,
  "n40": #b8b2b5,
  "n30": #cdc9ca,
  "n20": #cdc9ca,
  "n10": #ebe7e6,
  "n0": #fffef9,
  "b1": #c2da70,
  "b2": #46bee7,
  "b3": #ffaf57,
  "g1-start": #ffccd0,
  "g1-stop": #fff0da,
  "g2-start": #cd4344,
  "g2-stop": #dc6e13,
  "g3-start": #cf0089,
  "g3-stop": #cb1a65,
  "g4-start": #ff9929,
  "g4-stop": #f3cf0e,
  "blue-1": #e2f3f9,
  "blue-2": #c0dff0,
  "blue-3": #9acffa,
  "blue-4": #255a81,
  "blue-5": #000f2a,
  "green-1": #f0ffbe,
  "green-2": #e2f5a1,
  "green-3": #ccec6a,
  "green-4": #b0d93b,
  "yellow-1": #fdf5bd,
  "yellow-2": #fdee8a,
  "yellow-3": #fcdf4d,
  "yellow-4": #fbcd24,
  "orange-1": #ffeed4,
  "orange-2": #ffd8a9,
  "orange-3": #ffaf57,
  "orange-4": #fe9539,
  "red-1": #fdd4cb,
  "red-2": #fab6a7,
  "red-3": #f58c74,
  "red-4": #d84b2b,
  "red-5": #b40300,
  "video-n0": #ffffff,
  "video-n20": #eff1f4,
  "video-n30": #dbdedf,
  "video-n40": #d0d0d4,
  "video-n50": #ababb1,
  "video-n60": #73737d,
  "video-n70": #5c5c64,
  "video-n80": #36363a,
  "video-n99": #040707,
  "video-n100": #000000,
  "video-e80": #ff5449,
  "video-p30": #d1dcff,
  "video-p50": #658aff,
  "video-p60": #3169f9,
  "video-p70": #0749ed,
  "video-p72": #0034bb,
) !default;

// Options

$enable-smooth-scroll: true !default;
$enable-button-pointers: true !default;
$enable-rfs: true !default;
$enable-important-utilities: true !default;

// Spacing

// spacer-variables-maps

// TODO: Adjust spacing definitions
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer * 0.75,
  4: $spacer,
  5: $spacer * 1.25,
  6: $spacer * 1.5,
  7: $spacer * 1.75,
  8: $spacer * 2,
  9: $spacer * 2.25,
  10: $spacer * 2.5,
  11: $spacer * 2.75,
  12: $spacer * 3,
  13: $spacer * 3.25,
  14: $spacer * 3.5,
  15: $spacer * 3.75,
  16: $spacer * 4,
) !default;

// Position

// position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%,
) !default;

// Body
// Settings for the `<body>` element.

$body-bg: var(--n0) !default;
$body-color: var(--n100) !default;
$body-text-align: null !default;

// Links
// Style anchor elements.

$link-color: var(--p60) !default;
$link-decoration: none !default;
$link-hover-decoration: none !default;

// Paragraphs
// Style p element.

$paragraph-margin-bottom: 1rem !default;

// Grid breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1024px,
  xxl: 1200px,
  xxxl: 1400px,
) !default;

// Components
// Define common padding and border radius sizes and more.

// border-variables
$border-width: 1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
) !default;
$border-color: var(--n40) !default;

// border-radius-variables
$border-radius-xs: 8px !default;
$border-radius: 16px !default;
$border-radius-sm: 24px !default;
$border-radius-lg: 28px !default;
$border-radius-pill: 32px !default;
$border-radius-max: 100px !default;

// box-shadow-variables

$box-shadow: 0px 5px 16px 0px rgba(0 0 0 / 15%) !default;

// aspect-ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
) !default;

// Typography
// Font, line-height, and color for body text, headings, and more.

// font-variables
$font-family-sans-serif: greycliff-cf, sans-serif !default;
$font-family-base: var(--font-sans-serif) !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

// font-weight
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-weight-base: $font-weight-normal !default;

// Line height
$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-md: 1.35 !default;
$line-height-lg: 2 !default;

// font-size
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

// headings-variables
$headings-margin-bottom: $spacer * 0.5 !default;
$headings-font-family: greycliff-cf, sans-serif !default;
$headings-font-style: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;
$headings-font-feature-settings:
  "clig" off,
  "liga" off !default;

// type-variables

$small-font-size: 0.875em !default;
$sub-sup-font-size: 0.75em !default;
$text-muted: var(--n60) !default;
$initialism-font-size: $small-font-size !default;
$hr-margin-y: $spacer !default;
$hr-color: inherit !default;
$hr-height: $border-width !default;
$hr-opacity: 0.25 !default;
$legend-margin-bottom: 0.5rem !default;
$legend-font-size: 1.5rem !default;
$legend-font-weight: null !default;
$dt-font-weight: $font-weight-bold !default;
$tab-focus: inset 0 0 2px 2px rgba(0 103 244 / 70%);

// btn variables
$btn-padding: 0.375rem 0.75rem;
$btn-lg: 1.19rem 1.625rem;
$btn-transition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out,
  opacity 0.5s ease-in-out !default;
$input-transition:
  border-color 0.15s ease,
  border-radius 0.15s ease,
  height 0.15s ease;
